.landing-grid {
	max-width: 500px;
	max-height: 650px;
	margin: 0 auto;
	position: absolute;
	width: 100%;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	.logo svg {
		@media screen and (min-width: 370px) {
			width: 150px;
			height: auto;
		}
		@media screen and (min-width: 620px) {
			width: 170px;
			height: auto;
		}
		@media screen and (min-width: 720px) {
			width: 200px;
			height: auto;
		}
		
	}
}

.landing-background-wrap {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: var(--ion-color-primary);

	.landing-background-image {
		position: absolute;
		object-fit: cover;
		object-position: center bottom;
		width: 100%;
		height: 100%;
		opacity: 1;
		mix-blend-mode: multiply;
		
	}
}

.select-sport-business {
	text-align: center;
	color: #fff;
	
	flex-wrap: wrap;
	align-items: center;
	flex-direction: column;
	height: 100%;
	padding-bottom: 0;

	@media screen and (min-height: 420px ) {
		display: flex;
		padding-bottom: 5%;
	}
	@media screen and (min-height: 580px ) {
		padding-bottom: 20%;
		
	}
	@media screen and (min-height: 620px ) {
		padding-bottom: 40%;
		flex-direction: row;
	}
	.please-select {
		width: 100%;
		height: auto;
		flex-grow: 100;
		display: flex;
		align-items: flex-end;
		justify-content: center;
	}
	ion-segment-button {
		border: 2px solid #1DB59A;
		color: #fff;
		&:nth-of-type(1) {
			border-right: 0;
			border-radius: 40px 0 0 40px;
		}
		&:nth-of-type(2) {
			border-radius: 0 40px 40px 0;
			border-left: 0;
		}
		ion-label {
			color: #fff;
			text-transform: none;
			letter-spacing: 0px;
			font-size: 18px;
			margin-top: 20px;
    		margin-bottom: 20px;
		}
		&.segment-button-checked {
			background-color: var(--ion-color-primary);
			--indicator-color: rgba(0,0,0,0);
			--background-focused: rgba(0,0,0,0);
		}
	}

	.sport-business-selector {
		border: 2px solid #1DB59A;
		border-radius: 40px;
		display: flex;
		width: 100%;
		max-width: 420px;
		margin: 0 auto;
		flex-grow: 0;
		position: relative;
		div {
			flex-basis: 50%;
			padding: 18px;
			font-size: 18px;
			position: relative;
			z-index: 9;
			cursor: pointer;
			transition: 0.2s ease-out;
			&:hover {
				opacity: 0.6;
			}
			
		}
		&::before {
			content: "";
			position: absolute;
			width: 50%;
			height: 100%;
			background-color: var(--ion-color-primary);	
			border-radius: 40px;
			left: 0;
			top: 0;
			transform: translate(0,0);
			transition: 0.2s ease-out;
		}
		&.business::before {
			transform: translate(100%,0);
		}
		&.sports div:nth-of-type(1):hover {
			opacity: 1;
			cursor: default;
		}
		&.business div:nth-of-type(2):hover {
			opacity: 1;
			cursor: default;
		}
	}
}