$breakpoints: (
  xs: 23.4em,
  sm: 30em,
  md: 48em,
  lg: 62em,
  xl: 75em
);

@mixin respond-to($media) {

  @each $key,$val in $breakpoints {
      $i: index($breakpoints, ($media $val));

      @if $i {
          
          @if $media == #{$key} {
              @media only screen and (min-width: #{$val}) { @content; }
            } 

      }
  } 
}

.dugout-articles {
	// padding: 80px 0 0;
	color: var(--ion-color-dark);
}

.posts-header {
	padding: 60px 16px 0;
	@include respond-to('xs'){
		padding: 70px 24px 0;
	}
	@include respond-to('md'){
		padding: 80px 24px 0;
	}
	h1.posts-title {
		font-family: "neue-haas-unica", sans-serif;
		font-weight: 500;
		
		overflow-wrap: break-word;
		letter-spacing: -0.02em;
		margin-bottom: 0.5em;
		font-size: 2.6em;
		@include respond-to('xs'){
			font-size: 2.9em;
		}
		@include respond-to('sm'){
			font-size: 3.8em;
		}
		@include respond-to('md'){
			font-size: 5em;	
			padding-right: 1em;
		}
	}
	
}
	div.posts {
		max-width: 920px;
		margin-left: auto;
		margin-right: auto; 
	
		padding: 40px 16px 40px;
		@include respond-to('xs'){
			padding: 40px 24px 40px;
		}
	
		h1, h2, h3, h4, h5, h6 {
			font-family: "neue-haas-unica", sans-serif;
			font-weight: 700;
		}
		h3 {
			margin: 0;
			padding: 0;
		}
		.posts-container{
			// display: flex;
			// flex-wrap: wrap;
			
		}
		article.post {
			background-color: #fff;
			border-top: 2px solid var(--ion-color-primary);
			padding: 16px 16px 16px 24px;
			margin-bottom: 16px;
			display: flex;
			flex-wrap: wrap;
			position: relative;
			.post-info {
				order: 2;
				flex-grow: 1;
				padding: 8px 24px 8px 0;
				@include respond-to('md'){
					padding: 0 48px 24px 0;	
					order: 1;
				}
				@include respond-to('lg'){
					padding: 0 60px 0px 0;	
				}
				.entry-header__meta {
					font-size: 0.8em;
					padding: 20px 0;
				}
			}
			@include respond-to('md'){
				flex-wrap: nowrap;
			}
			.post-image {
				order: 1;
				width: 320px;
				padding: 12px 0;
				flex-shrink: 0;
				flex-grow: 0;
				@include respond-to('md'){
					order: 2;
					padding: 0;
				}

			}
			
			h4 {
				font-size: 1.8em;
				font-weight: 500;
				overflow-wrap: break-word;
				line-height: 1.25;
				&:hover {
					color: var(--ion-color-primary);
				}
			}
			img {
				transition: 0.25s;
				max-width: 100%;
				height: auto;
				

			}
			a:hover {
				color: var(--ion-color-primary);
			}
			
			img:hover {
				opacity: 0.7;
			}
			
			.post-category {
				position: absolute;
				top: 12px;
				right: 12px;
				padding: 2px 16px 3px;
				background-color: var(--ion-color-primary);
				color: #fff;
				letter-spacing: 0.075em; 
				font-size: 0.7em;
				text-transform: uppercase;
				border-radius: 10px;
			}
			
		}
	}

	.navigation {
		text-align: center;
		padding: 0 0 50px;
	}

.navigation li a,
.navigation li a:hover,
.navigation li.active a,
.navigation li.disabled {
    color: var(--ion-color-dark);
    text-decoration: none;
}
 
.navigation li {
    display: inline;
}
 
.navigation li a,
.navigation li a:hover,
.navigation li.active a,
.navigation li.disabled {
    //background-color: $light-blue;
   //border-radius: 3px;
   	
    cursor: pointer;
    padding: 0.5rem;
}
 
.navigation li a:hover,
.navigation li.active a {
    //background-color: $light-blue;
    color: var(--ion-color-primary);
}