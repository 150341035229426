.favourites.dashboard .dashboard-content-column-2 {
	display: block;
	@media screen and (min-width: 768px) {
		display: flex;
	}
}

.favourites .dashboard-content {
	padding-bottom: 110px;

	@media screen and (min-width: 768px) {}
	padding-bottom: 0;
}