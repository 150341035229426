.editor-wrap {
	background-color: #fff;
	padding: 16px 8px;
	border-radius: 5px;

	@media screen and (min-width: 576px) {
		padding: 16px;
	}
}

.editor-content {
	.content {
		padding-top: 40px;
		padding-bottom: 120px;
	}
}

.editor-section {
	margin: 8px 0;
	padding: 8px 8px;
	border-top: 1px solid #F0F0F0;

	.editor-section-top {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 5px;
	}


	

	.editor-section-top-buttons {
		display: flex;
		gap: 12px;
		align-items: flex-end;
	}

	.error-message {
		position: relative;
	}

	.editor-section-button {
		color: var(--ion-color-primary);
		font-weight: 500;
		cursor: pointer;
		transition: 0.25s color ease-out;
		&:hover {
			color: var(--ion-color-primary-hover);
		}

		&.secondary {
			color: var(--ion-color-tertiary);
			font-size: 0.9em;
			font-weight: 400;
			&:hover {
				color: var(--ion-color-tertiary-tint);
			}
		}
	}

	.editor-section-bottom {
		padding: 0 0 0;
		.text-editor-wrap {
			display: none;
		}
		&.show-editor .text-editor-wrap {
			display: block;
		}
		.upload-image, .current-image, ion-input {
			margin-top: 15px;
		}
	}

	.current-image img {
		max-width: 500px;
		width: 100%;
    	height: auto;
	}

	ion-input, ion-textarea {
		border: 1px solid #dddddd;
	}

	.text-editor-content {
		padding: 0;
	}

}

.editor-section-title {
	font-weight: 700;
	font-size: 1.2em;
	letter-spacing: -0.02em;
	color: var(--ion-color-dark);
}

.new-news-article ion-input, .new-news-article ion-textarea {
	border: 1px solid #dddddd;
}

.price .editor-section-bottom  {
	position: relative;
    padding-left: 25px;
}

.currency-display {
	display: inline-block;
    position: absolute;
    left: 5px;
    font-size: 1.2em;
    top: 50%;
    margin-bottom: 0px;
    color: var(--ion-color-medium);
    transform: translate(0, -50%);
    margin-top: 7px;
}

ion-input.price {
	--padding-end: 8px;
}