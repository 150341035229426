.opportunities {
	padding: 0 8px 0px;
}

.opportunities .opportunity {
	cursor: pointer;
	border-radius: 5px;
	overflow: hidden;
	margin: 8px 0;
	display: flex;
	padding: 0;
	align-items: stretch;
	position: relative;
	border-left: 8px var(--ion-color-primary) solid;
	background-color: var(--ion-color-light);
	transition: 0.25s background-color ease, 
		0.25s box-shadow ease;
	box-shadow: 
		0px 0px 20px 0px rgba(0,0,0,0),
		0px 2px 2px 0px rgba(0,0,0,0),
		0px 1px 5px 0px rgba(0,0,0,0);	
	&:hover { 
		background-color: var(--ion-color-light);
		box-shadow: 
		0px 0px 20px 0px rgba(0,0,0,0.07),
		0px 2px 2px 0px rgba(0,0,0,0.06),
		0px 1px 5px 0px rgba(0,0,0,0.03); 
	}
	.opportunity-image-thumb {
		flex-shrink: 0;
		width: 55px;
    	height: 55px;
		position: absolute;
		top: 10px;
		right: 10px;
		overflow: hidden;
		border-radius: 4px;
		@media screen and ( min-width: 576px ) {
			width: 150px;
			border-radius: 0px;
			top: 0;
			right: 0;
			height: auto;
			position: relative;
			margin: 12px;
		}
		
		.opportunity-image {
			position: absolute;
			top: 0;
			left: 0;
			border-radius: 4px;
			width: 65px;
    		height: 65px;
			max-height: 80px;
			object-fit: cover;
			object-position: center center;
			
			@media screen and ( min-width: 576px ) {
				max-height: 200px;
				width: 100%;
				height: 100%;
				top: 50%;
				transform: translate(0,-50%);
			}
		}
	}
	.price {
		font-size: 3em;
		font-family: alternate-gothic-no-3-d, sans-serif;
		color: var(--ion-color-primary);
		margin: 0;
	}
	.title {
		font-weight: 600;
		margin: 0;
		color: var(--ion-color-dark);
		font-size: 1.4em;
		line-height: 1.2;
	}
	.description {
		margin: 8px 0 0;
		font-size: 1em;
		line-height: 1.5;
	}
	.opportunity-details {
		flex-shrink: 2;
		flex-grow: 2;
		padding: 16px 16px 16px 18px;
	}
	&.opportunity-status-draft {
		border-left: #b5b5b5 8px solid;
		.price {
			color: #b5b5b5;
		}
		.title {
			color: #8a8a8a;
		}
		.favourite-button {
			color: #8a8a8a;
		}
	}
	&.opportunity-status-expired {
		border-left: #cc7878 8px solid;
		.price {
			color: #b5b5b5;
		}
		.title {
			color: #8a8a8a;
		}
		.favourite-button {
			color: #8a8a8a;
		}
	}
}

.other-sponsorship-ideas {
	color: var(--ion-color-medium);
}