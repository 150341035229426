ul.profile-todo {
	list-style: none;
	padding: 12px 8px;
	margin: 0;
	li {
		padding: 0 0 6px;
		display: flex;
		align-items: center;
		font-weight: 500;
		font-size: 1em;
		cursor: pointer;
		transition: 0.2s ease-out;
		&:before {
			content: "";
			width: 15px;
			height: 15px;
			border: 2px solid var(--ion-color-primary);
			border-radius: 3px;
			display: inline-block;
			margin: 2px 10px 0 0;
			background-color: #fff;
		}
		&:hover {
			color: var(--ion-color-primary);
		}
	}
}