h1, h2, h3, h4, h5, h6 {
	font-family: alternate-gothic-no-3-d, sans-serif;
}

h1 {
	font-size: 3em;
}

h2 {
	font-size: 2em;
}

.ion-color-dark {
	color: var(--ion-color-dark);
}
.ion-color-primary {
	color: var(--ion-color-primary);
}

.section-title {
	font-size: 1.5em;
	font-weight: 600;
}