.content.build-profile {
	height: 100%;
	width: 100%;
	padding-top: 0;
    padding-bottom: 80px;
	@media screen and (min-width: 768px) {
		padding-top: 100px;
		max-width: 600px;
		padding-bottom: 40px;
	}
}

.build-navigation {
	justify-self: flex-end;
	align-items: center;
	ion-button.button-block, .pay-button.button-block {
		margin: 8px 0;
	}
	ion-button {
		width: 59px;
		height: 59px; 
		&.link {
			margin: 0 10px 0 auto;	
			&.restart {
				margin: 0 auto 0 10px;
			}
		}
	}
}

.section-input {
	padding: 0 0 8px;
	height: 77px;
}

.short-description-step {
	.section-input {
		height: 122px;
		padding: 0 0 4px;
		ion-textarea {
			height: 100%;
			.textarea-wrapper, textarea {
				height: 100%;
				line-height: 1.4;
			}
		}
	}
}

.build-social-media {
	.section-input {
		height: 200px;
		ion-input {
		
			--padding-top: 16px;
			--padding-end: 8px;
			--padding-bottom: 16px;
			--padding-start: 8px;
			
		}
	}
}

.build-step.build-achievements {
	.section-input {
		height: auto;
    	margin: auto 0 0;
		overflow: scroll;
		ion-input {
			--padding-top: 12px;
			--padding-end: 8px;
			--padding-bottom: 12px;
			--padding-start: 8px;
			
		}
	}
	.section-text {
		padding-top: 24px;
	}
	ion-button.button-block {
		margin: 12px 0;
	}
}

.section-text {
	min-height: 121px;
	display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.build-step.description-step .section-input {
	height: auto;
	overflow: hidden;
}



.RichEditor-editor .public-DraftEditor-content {
	max-height: 45vh;
}

ion-popover.description-hints-tips {
	--width: 100%;
	color: var(--ion-color-dark);
    --max-width: 450px;

	.popover-content {
		margin: 0 16px;
		width: calc(100% - 37px);
	}
	ul {
		list-style: none;
	  }
	  
	ul li::before {
	content: "\2022";
	color: var(--ion-color-primary);
	font-weight: bold;
	display: inline-block;
	width: 1em;
	margin-left: -1em;
	}
}

.build-step {
	

	width: 100%;
	height: calc(100% - 25px);
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	padding: 0 24px;
	ion-input {
		--background: #fff;
		border: 1px solid #EDEDED;
		--padding-top: 20px;
		--padding-end: 8px;
		--padding-bottom: 20px;
		--padding-start: 12px;
		font-size: 18px;
		&.sport-input input[disabled] {
			cursor: pointer;
			opacity: 1;
		}
	}
	ion-textarea {
		--background: #fff;
		border: 1px solid #EDEDED;
		--padding-top: 8px;
		--padding-end: 8px;
		--padding-bottom: 8px;
		--padding-start: 8px;
		font-size: 16px;
		margin: 0;
	}

	

	.example-profile {
		width: 100%;
		flex-grow: 1;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-shrink: 1;
		overflow: hidden;
		margin: 0 auto;
		padding: 8px 0 0;

		.avatar {
			position: relative;
			margin: 0;
			left: auto;
			top: auto;
		}

		.profile-card.example {
			max-width: 300px;
    		width: 85%;
			padding: 8px 8px 0;
			flex-direction: column;
		}

		.profile-card.example div.profile-image {
			background-color: #fff;
			width: 100%;
			height: auto;
			flex-basis: auto;
			aspect-ratio: 3 / 1;

			@supports not (aspect-ratio: auto) { 
				padding-top: 33.333%;
				
			}
		}

		.profile-facts {
			padding: 2px 0 0;
			font-size: 13px;
			ion-icon {
				font-size: 18px;
			}
			p {
				padding: 0 5px 0;	
			}
			> div {
				padding: 4px 12px 4px 0;
			}
		}
		.profile-information {
			padding: 12px 6px 6px 6px;
			.name {
				font-size: 1.2em;
				line-height: 1.3;
			}
			.sport {
				font-size: 1em;
			}
			.description {
				font-size: 0.8em;
			}
		}
	}

	.editor-section {
		margin: 0;
		padding: 0;
		.profile-build-upload {
			ion-button {
				--ripple-color: var(--ion-color-tertiary);
				margin: 8px 0;
			}
			p {
				font-size: 0.9em;
   		 		padding: 0 62px;
			}
		}
	}
}



@keyframes fade--in {
	from {opacity: 0;}
	to {opacity: 1;}
}

@keyframes fade--in--bottom {
	from {opacity: 0; transform: translate(0, 20px); }
	to {opacity: 1; transform: translate(0, 0);}
}

@keyframes wait {
    0% { opacity: 0; }
    100% { opacity: 0; }
}

@mixin fade--in--bottom($waitTime, $animationTime) {
    animation: 
        wait #{$waitTime},
        fade--in--bottom #{$animationTime} #{$waitTime} ease-out;
}

@mixin fade-in($waitTime, $animationTime) {
    animation: 
        wait #{$waitTime},
        fade--in #{$animationTime} #{$waitTime} ease-out;
}

.build-step .avatar {
	@include fade--in--bottom(0s, 0.5s);
}

.build-profile-name .example-profile {
	@include fade--in--bottom(0s, 0.5s);
}

.section-text p:nth-of-type(1) {
	@include fade--in--bottom(0.2s, 0.4s);
}

.section-text p:nth-of-type(2) {
	@include fade--in--bottom(0.25s, 0.4s);
}

.section-input {
	@include fade--in--bottom(0.3s, 0.4s);
}
