$breakpoints: (
  xs: 23.4em,
  sm: 30em,
  md: 48em,
  lg: 62em,
  xl: 75em
);

@mixin respond-to($media) {

  @each $key,$val in $breakpoints {
      $i: index($breakpoints, ($media $val));

      @if $i {
          
          @if $media == #{$key} {
              @media only screen and (min-width: #{$val}) { @content; }
            } 

      }
  } 
}

.dugout-articles {
	// padding: 80px 0 0;
	color: var(--ion-color-dark);
}

.posts-header {
	padding: 60px 16px 0;
	@include respond-to('xs'){
		padding: 70px 24px 0;
	}
	@include respond-to('md'){
		padding: 80px 24px 0;
	}
	h1.posts-title {
		font-family: "neue-haas-unica", sans-serif;
		font-weight: 500;
		
		overflow-wrap: break-word;
		letter-spacing: -0.02em;
		margin-bottom: 0.5em;
		font-size: 2.6em;
		@include respond-to('xs'){
			font-size: 2.9em;
		}
		@include respond-to('sm'){
			font-size: 3.8em;
		}
		@include respond-to('md'){
			font-size: 5em;	
			padding-right: 1em;
		}
	}
	
}

.dugout-categories {
	.dugout-category {
		img {
			mix-blend-mode: luminosity;
			opacity: 0.4;
			transition: 0.25s;
		}
		&:hover {
			cursor: pointer;
			
			img {
				// mix-blend-mode: normal;
				opacity: 0.8;
			}
		}
	}
}
