.profile-card {
	display: flex;
	flex-wrap: wrap;
	background-color: #fff;
	border-radius: 5px;
	padding: 12px;
	cursor: pointer;
	//max-width: 400px;
	margin: 10px auto;
	max-width: 920px;
	// @media screen and (min-width: 680px) {
	// 	flex-wrap: nowrap;
	// 	margin: 10px 5px;
	// 	max-width: 100%;
	// }
	transition: 0.25s background-color ease, 
		0.25s box-shadow ease;
	box-shadow: 
		0px 0px 20px 0px rgba(0,0,0,0),
		0px 2px 2px 0px rgba(0,0,0,0),
		0px 1px 5px 0px rgba(0,0,0,0);	
	&:hover { 
		background-color:rgb(245, 248, 247);
		box-shadow: 
		0px 0px 20px 0px rgba(0,0,0,0.07),
		0px 2px 2px 0px rgba(0,0,0,0.06),
		0px 1px 5px 0px rgba(0,0,0,0.03); 
	}


	@media screen and (min-width: 768px) {
		flex-wrap: wrap;
		width: 100%;
		
		// max-width: 400px;
	}
	@media screen and (min-width: 1024px) {
		flex-wrap: nowrap;


		
		// width: 100%;
		// max-width: 400px;
	}
	ion-icon {
		font-size: 28px;
	}

	div.profile-image {
		width: 100%;
		aspect-ratio: 3 / 1;
		// padding-top: 33.333%;
		position: relative;

		flex-grow: 0;
		flex-shrink: 0;
		
		// aspect ratio fallback
		@supports not (aspect-ratio: auto) { 
			padding-top: 33.333%;
			
		}
		@media screen and (min-width: 1024px) {
			width: 300px;
			flex-basis: 300px;
			aspect-ratio: 3 / 2;
			@supports not (aspect-ratio: auto) { 
				padding-top: 66.6666%;
				
			}
		}

		border-radius: 4px;
		overflow: hidden;
		background-color: var(--ion-color-tertiary-tint); 
		img {
			position: absolute;
			top: 0;
			left: 0;
			display: block;
			object-fit: cover;

			width: 100%; 
			height: 100%;
			
		}
	}

	
	.profile-information {
		padding: 18px 8px 8px 8px;
		flex-shrink: 1;
		flex-grow: 1;
		width: auto;
		flex-basis: min-content;
		@media screen and (min-width: 1024px) {
			padding: 8px 18px 8px 24px;
		}
	}
	.favourite-button {
		margin-top: 12px; 
		@media screen and (min-width: 1024px) { 
			margin-top: 5px;
		}
	}
	.name {
		text-transform: none;
		font-size: 1.6em;
		font-weight: 700;
		color: var(--ion-color-dark);
		padding: 0;
		margin: 0;
	}

	.sport {
		color: var(--ion-color-primary);
		font-size: 1.2em;
		font-weight: 700;
		padding: 0 0 8px;
		margin: 0;
	}

	.description {
		padding: 0;
		margin: 0;
		color: var(--ion-color-medium);
		width: 100%;
		// height: 50px;
		// overflow: hidden;
	}

	.profile-facts {
		margin: 0 0 0 -3px;
		padding: 12px 0 0;
		display: flex;
		flex-wrap: wrap;
		> div {
			display: flex;
			align-items: center;
			padding: 4px 18px 4px 0;
			p {
				padding: 0 8px;
				margin: 0;
				color: #616161;
			}
		}
	}
	&.narrow {
		flex-wrap: wrap;
		.profile-image {
			width: 100%;
			flex-basis: 100%;
			aspect-ratio: 3 / 1;
			
			
			// aspect ratio fallback
			@supports not (aspect-ratio: auto) { 
				padding-top: 33.333%;
				
			}
		}
		.profile-information {
			padding: 8px 8px 0px 8px;
		}
		.favourite-button {
			margin-top: 12px;
		}
		.description {
			font-size: 0.95em;
			
		}
		.profile-facts {
			padding: 8px 0 0;
			font-size: 0.9em;
		}
	}

	.dashboard-content-column-1 & {
		.favourite-button {
			display: none;
		}
	}
	
	&.condensed {
		padding: 8px 8px 4px;
		flex-wrap: wrap;
		width: calc(100% - 8px);
		margin: 4px;
		position: relative;

		@media screen and (min-width: 1360px) {
			width: calc(50% - 8px);
		}
		// background-color:rgb(245, 248, 247);
		box-shadow: 
		0px 0px 3px 0px rgba(0,0,0,0.05),
		0px 0px 2px 0px rgba(0,0,0,0.05),
		0px 0px 5px 0px rgba(0,0,0,0.03); 

		div.profile-image {
			@media screen and (min-width: 1024px) {
				aspect-ratio: 3 / 1;
				width: 100%;
    			flex-basis: 100%;
				@supports not (aspect-ratio: auto) { 
					padding-top: 33.333%;
					
				}
			}
		}
		.profile-information {
			padding: 4px 8px 4px 4px;
		}
		.favourite-button {	
			position: absolute;
			right: 12px;
    		top: 10px;
			width: 32px;
    		height: 32px;
			ion-icon {
				font-size: 20px;
			}
		}
		.name {
			font-size: 1.3em;
		}
		.sport {
			font-size: 1em;
		}
		.description {
			font-size: 0.85em;
			line-height: 1.5;
		}
		.profile-facts {
			padding: 8px 0 0;
			font-size: 0.85em;
			ion-icon {
				font-size: 20px;
			}
		}
	}
}