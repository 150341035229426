.login-form input:-internal-autofill-selected {
	background-color: #fff !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}

ion-input {
	--padding-top: 15px;
}

.item-label-stacked.sc-ion-input-md-h, 
.item-label-stacked .sc-ion-input-md-h, 
.item-label-floating.sc-ion-input-md-h, 
.item-label-floating .sc-ion-input-md-h {
	--padding-top: 15px;
	// font-weight: 700;
	color: var(--ion-color-dark);
	
}

.label-stacked.sc-ion-label-md-h {
	font-weight: 700;
}

ion-label {
	color: var(--ion-color-dark);
	position: relative;
	z-index: 9;
}

ion-button.arrow {
	display: inline-block;
}

ion-input {
	position: relative;
	.error-message {
		position: absolute;
		top: 8px;
		right: 0;
		color: var(--ion-color-danger);
	}
}

.form-secondary-buttons {
	display: flex;
	justify-content: space-between;
	p {
		margin: 0;
		padding: 12px 0;
		font-size: 0.9em;
		color: var(--ion-color-tertiary);
		cursor: pointer;
		&:hover {
			color: var(--ion-color-primary)
		}
		&.text-center {
			text-align: center;
			display: block;
			flex-grow: 1;
		}
		

	}
}