.text-editor-wrap {

	padding: 15px 0 10px;
	width: 100%;

	

	h1, h2, h3, h4, h5, h6 {
		font-family: 'neue-haas-unica', sans-serif;
		font-weight: 700;
		letter-spacing: -0.01em;
		
	}

	h2 {
		font-size: 1.8em;
	}
}

.RichEditor-root {
	background: #fff;
	border: 1px solid #ddd;
	// font-family: 'Georgia', serif;
	font-size: 14px;
	padding: 6px 5px 15px;
	display: flex;
	flex-wrap: wrap;
  }
  
  .RichEditor-editor {
	border-top: 1px solid #ddd;
	cursor: text;
	font-size: 16px;
	margin-top: 5px;
	flex-basis: 100%;
	padding: 0 10px;
  }
  
  .RichEditor-editor .public-DraftEditorPlaceholder-root,
  .RichEditor-editor .public-DraftEditor-content {
	margin: 0 -15px -15px;
	padding: 15px;
  }
  
  .RichEditor-editor .public-DraftEditor-content {
	min-height: 100px;
	max-height: 80vh;
    overflow: scroll;
  }
  
  .RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
	display: none;
  }
  
  .RichEditor-editor .RichEditor-blockquote {
	border-left: 5px solid #eee;
	color: #666;
	font-size: 18px;
	// font-family: 'Hoefler Text', 'Georgia', serif;
	font-style: italic;
	margin: 16px 0;
	padding: 10px 20px;
  }
  
  .RichEditor-editor .public-DraftStyleDefault-pre {
	background-color: rgba(0, 0, 0, 0.05);
	font-family: 'Inconsolata', 'Menlo', 'Consolas', monospace;
	font-size: 16px;
	padding: 20px;
  }
  
  .RichEditor-controls {
	// font-family: 'Helvetica', sans-serif;
	font-size: 14px;
	user-select: none;

	display: flex;
    align-items: center;
	padding-right: 6px;
	padding-left: 6px;
	&:nth-of-type(2) {
		border-left: 2px solid #DDDDDD;
		padding-left: 12px;
	}
	&:nth-of-type(3) {
		border-left: 2px solid #DDDDDD;
		padding-left: 12px;
	}
  }
  
  .RichEditor-styleButton {
	color: var(--ion-color-black);
	cursor: pointer;
	margin-right: 6px;
	padding: 5px 2px;
	display: inline-block;
	vertical-align: middle;
	svg {
		padding: 3px;
		display: block;
		&.underline-icon {
			margin-top: 2px;
		}
	}
  }
  
  .RichEditor-activeButton {
	color: var(--ion-color-primary);
  }

  div > .public-DraftStyleDefault-block {
	margin-bottom: 1em;
  } 