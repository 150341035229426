.hello {
	color: var(--ion-color-dark);
}
.greeting {
	color: var(--ion-color-tertiary-shade);
}

// .dashboard .logout-button {
// 	margin-top: 40px;
// }
@media screen and ( min-width: 768px ) {
	.hello-message {
		padding: 10px;
	}
}

.dashboard-section-title {
	text-transform: none;
    font-size: 1.6em;
    font-weight: 700;
    color: var(--ion-color-dark);
    padding: 0;
    margin: 0;
}

.dashboard-content {
	padding-bottom: 100px;
}

@media screen and ( min-width: 768px ) {
	.dashboard-content {
		padding-bottom: 0px;
		padding-top: 70px;
		margin: 0 auto;
		display: flex;
	}
}

.dashboard-content-column-1 {
	width: 100%;
	display: flex;
	flex-direction: column;
	@media screen and ( min-width: 768px ) {
		width: 29%;
		padding-bottom: 0;
		display: flex;
		flex-direction: column;
		height: calc(100vh - 102px);
		padding: 10px 0 0;
	}
	.profile-card {
		order: 3;
		display: none;
		@media screen and ( min-width: 768px ) {
			display: block;
			margin-top: 0;
		}
	}
}

.dashboard-actions {
	order: 2;
	background: #fff;
    border-radius: 5px;
	.menu-list {
		border-radius: 5px;
		padding: 12px;
	}
	@media screen and ( min-width: 768px ) {
		order: 3;
		flex-grow: 1;
		overflow: scroll;
		.menu-list {
			height: 100%;
		}
	}

	a {
		text-decoration: none;
	}
}

.dashboard-content {

	.hello-user {
		// display: flex;
		align-items: center;
		background-color: #fff;
		border-radius: 5px;
		padding: 12px;
		display: none;
		order: 1;
		&.mobile {
			display: flex;
		}
		@media screen and ( min-width: 768px ) {
			&.mobile {
				display: none;
			}
			display: flex;
		}
		.avatar {
			position: relative;
			margin: 0;
			left: auto;
			top: auto;
		}
		.avatar-image {
			width: 81px;
			height: 81px;
			display: block;
		}
		.hello-message {
			padding: 10px 12px 16px;
		}
	}
}



.dashboard-content-column-2 {
	width: 100%;
	display: none;
	@media screen and ( min-width: 768px ) {
		// display: block;
		width: 40%;
		margin: 0 1%;

		
		padding-bottom: 0;
		display: flex;
		flex-direction: column;
		height: calc(100vh - 102px);
	}	
	padding: 10px 0 0;

	.hello-user {
		order: 1;
	}
	

	.user-opportunities {
		order: 2;
		background-color: #fff;
		border-radius: 0 0 5px 5px;
		padding: 12px;
		background-color: #fff;
		flex-grow: 1;
		overflow: scroll;

		.opportunities {
			padding: 0;
		}
	}
	.opportunity-counts {
		padding: 12px 0 0;
		display: flex;
		flex-wrap: wrap;
		div {
			display: flex;
			align-items: baseline;
			padding: 0 0 0 12px;
			h2 {
				margin: 0;
				padding: 0;
				line-height: 1;
				color: var(--ion-color-primary);
				font-size: 2.8em;
				letter-spacing: -0.02em;
			}
			p {
				margin: 00;
				padding: 0;
				line-height: 1;
				color: var(--ion-color-dark);
				padding: 0 0 0 5px;
				font-weight: 500;
			}
			&:nth-of-type(1) {
				padding: 0;
			}
			&:nth-of-type(2) {
				opacity: 0.6;
			}
			&:nth-of-type(3) {
				opacity: 0.4;
				h2 {
					color: var(--ion-color-medium);	
				}
			}
			&.successful {
				p {
					color: var(--ion-color-primary);
				}
			}
		}
	}
}

.dashboard-content-column-3 {
	width: 100%;
	color: var(--ion-color-dark);
	padding-bottom: 100px; 
	padding: 10px 0 0;
	@media screen and ( min-width: 768px ) {
		width: 29%;
		padding-bottom: 0;

		display: flex;
		flex-direction: column;
		height: calc(100vh - 102px);
	}
	.dashboard-notifications {
		display: none;
		background-color: #fff;
		border-radius: 5px;
		padding: 12px;
		margin: 0px 0 10px;
		@media screen and ( min-width: 768px ) {
			display: block;	
		}
	}
	.dashboard-notification {
		padding: 8px 0 0;
		display: flex;
		.icon {
			width: 57px;
			height: 57px;
			background-color: #E0FAF3;
			border-radius: 30px;
			transition: 0.25s background-color ease-out;
			position: relative;
		}
		ion-icon {
			position: relative;
			width: 32px;
			height: 32px;
			padding: 13px;
		}
		.icon-inner, svg {
			display: block;
			height: 100%;
			width: 100%;
		}
		p.main-text {
			font-weight: 600;
			line-height: 1;
			margin-bottom: 5px;
			letter-spacing: -0.01em;
		}
		p.sub-text {
			font-size: 0.9em;
		}
		.text {
			padding: 0 8px 0 12px;
		}

	}
	.news-feed {
		background-color: #fff;
		padding: 12px 16px;
		border-radius: 5px;
	}
}

.news-article {
	color: var(--ion-color-dark);
	border-bottom: 1px solid #EDEDED;
	margin-bottom: 8px;
	.news-article-header {
		display: flex;
		align-items: baseline;
	}
	.news-title {
		flex-grow: 1;
		font-size: 1.2em;
		font-weight: 700;
		
		margin: 0;
		padding: 0;
	}
	.news-date {
		font-size: 0.9em;
		font-weight: 500;
		margin: 0;
		padding: 0;
		color: var(--ion-color-medium);
	}
	.news-description {
		font-size: 0.9em;
		margin-top: 8px;
	}
	.news-link {
		color: var(--ion-color-primary);
		display: flex;
		align-items: center;
		font-size: 0.9em;
		margin-bottom: 8px;
		ion-icon {
			margin-right: 5px;
		}
		span {
			display: inline-block;
			margin-bottom: 3px;
		}
	}
}