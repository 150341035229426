.search-profiles-content {
	display: flex;
	min-height: 100vh;
	margin: 0 -16px;
	
}

.toggle-sidebar-button {
	position: fixed;
	bottom: 79px;
	z-index: 999;
	width: 100%;
	padding: 0 8px;
	background-color: #fff;
	border-top: 1px solid #e6e6e6;
	box-shadow: 0 3px 10px 0px rgba(0, 0, 0, 0.16);
	display: block;
	ion-button {
		margin: 12px 0;
	}
	@media screen and ( min-width: 768px ) {
		display: none;
	}
}

.sidebar {
	position: fixed;
	overflow: scroll;
	right: 100%;
	top: 0;
	height: 100%;
	background-color: #fff;
	color: var(--ion-color-dark);
	z-index: 999;
	
	padding: 18px 24px 100px;
	width: 100%;
	box-shadow: 0 3px 10px 0px rgba(0, 0, 0, 0.16);
	display: flex;
	flex-direction: column;

	
	@media screen and ( min-width: 500px ) {
		padding: 30px 24px 40px;
	}	
	&.show-sidebar {
		transform: translate(100%, 0);
		
	}
	
	@media screen and ( min-width: 768px ) {
			left: 0;
			right: auto;
			padding: 40px 16px 20px;
			padding-top: 110px;
			max-width: 320px;
			width: 90%;

		}
	h1 {
		margin: 0;
		padding: 0 0 0px;
		text-transform: uppercase;
		letter-spacing: -0.02em;
		color: var(--ion-color-dark);
		line-height: 0.9;
		@media screen and ( min-width: 500px ) {
			padding: 0 0 8px;
		}	
	}
	.results {
		padding: 0 0 0px;
		margin: 0;
		font-weight: 500;
		@media screen and ( min-width: 500px ) {
			padding: 0 0 8px;
		}
	}
	.filter-by {
		font-weight: 700;
		font-size: 1.25em;
		padding: 0 0 8px;
		margin: 0;
	}
}

.profiles-content .content {
	width: 100%;
    margin: 0 0 0 auto;
	padding-top: 20px;
	padding-bottom: 180px;
	padding-left: 8px;
	padding-right: 8px;
	@media screen and ( min-width: 768px ) {
		padding-top: 100px;
		width: 100%;
		padding-bottom: 100px; 
		padding-left: 320px;
		max-width: 100%;
	}

} 


.my-masonry-grid {
	display: flex;
	margin-left: 0px; /* gutter size offset */
	width: auto;
  }
  .my-masonry-grid_column {
	padding-left: 0px; /* gutter size */
	background-clip: padding-box;
  }
  
  /* Style your items */
  .my-masonry-grid_column > ion-card { 
	/* change div to reference your elements you put in <Masonry> */
	// background: grey;
	margin-bottom: 10px;
  }


//   @media (max-width: 600px) {
// 	.my-masonry-grid {
// 	  margin-left: -15px; /* gutter size offset */
// 	}
// 	.my-masonry-grid_column {
// 	  padding-left: 15px; /* gutter size offset */
// 	}
// 	.my-masonry-grid_column > div {
// 	  margin-bottom: 15px; /* space between items */
// 	}
//   }

.filter-section {
	
	border-top: 1px solid #F0F0F0;

	margin: 4px 0;
    padding: 4px 0px 0;
	@media screen and ( min-width: 500px ) {
		margin: 8px 0;
		padding: 8px 0px 0;
	}
	
	.filter-section-top {
		display: flex;
		align-items: center;
	}
	.filter-section-title-col {
		flex-grow: 1;
	}
	.filter-section-title {
		font-weight: 700;
		margin: 0; 
		padding: 0;
		font-size: 1.1em;
		line-height: 1;
		@media screen and ( min-width: 500px ) {
			line-height: 1.4;
		}
	}
	.clear {
		font-weight: 500;
		font-size: 0.9em;
		color: var(--ion-color-primary);
		cursor: pointer;
		&:hover {
			opacity: 0.7;
		}
	}
}

.filter-section ion-checkbox {
	--size: 21px;
	--border-width: 1px;
	--border-radius: 5px;
	--border-color: #D6D6D6;
	background-color: #ffffff;
	
	&.checkbox-checked {
		--border-width: 3px;
	}
	
	.checkbox-icon {
		display: none;
		
	}
}  

.filter-section-bottom {

	padding: 4px 0 0;

	@media screen and ( min-width: 500px ) {
		padding: 8px 0;
	}
}

.filter-section.sports {
	padding: 4px 0px 0px;

	margin: 8px 0 0;
	display: flex;
    flex-direction: column;
	overflow: hidden;
	flex-grow: 1;

	@media screen and ( min-width: 500px ) {
		padding: 8px 0px 0px;
	}
	
	.filter-section-bottom {
		background-color: #fff;
		padding: 0;
		margin: 8px 0 0;
		border-radius: 5px;
		border: 1px solid #F0F0F0;
		flex-shrink: 1;
		overflow: scroll;
		flex-grow: 1;
		.sports {
			padding: 8px;
			
		}
	}
}

.sports .sport {
	display: flex;
	align-items: center;
	padding: 0 0 8px;
	.checkbox-label {
		flex-grow: 1;
		padding: 0 8px;
		cursor: pointer;
	}
	.checkbox-count {
		color: var(--ion-color-medium);
		font-size: 0.9em;
		padding: 0 8px;
	}
}

.view-more-sports {
	font-size: 0.8em;
	color: #C1C1C1;
	font-weight: 600;
	padding: 0 0 4px;
	cursor: pointer;
	transition: 0.25s ease-out;
	&:hover {
		color: var(--ion-color-medium);	
	}
}

.range-graph {
	// padding: 0 14px;
	margin-top: 16px;
	display: flex;
	height: 40px;
	align-items: flex-end;
	margin-bottom: -28px;
	.distance-group {
		flex-grow: 1;
		background-color: #f3f3f3;
		margin: 0 2px;
		border-radius: 4px 4px 0 0;
		&.active {
			background-color: #c6f8ea;
		}
	}
	.dashboard-search & {
		height: 0;
	}
}

ion-range {
	padding-inline: 0px;
}

.location-distance {
	margin: 0;
	padding: 0 0px;
	font-weight: 600;
	font-size: 0.95em;
	color: var(--ion-color-primary);
	margin-top: -15px;
}

.save-search {
	ion-button.button-block {
		margin: 0;
	}
}

.filter-section-bottom .google-places {
	z-index: 9999;
}

.dashboard-content-column-1 .sidebar {
	position: relative;
    top: auto;
    box-shadow: none;
    border-radius: 5px;
    overflow: auto;
    left: auto;
    padding: 16px;
    width: 100%;
    max-width: 100%;
	right: auto;
}

// ion-modal.save-search-modal {
// 	padding: 16px;
// 	--border-radius: 32px;
// }

.save-search {
	position: relative;
}

.saved-search-name {
	// position: absolute;
	// top: 0;
	// left: 0;
	// width: 100%;
	height: 51px;
	position: relative;

	ion-input {
		border: 1px solid #dddddd;
		border-radius: 32px;
		background-color: #fff;
		--padding-end: 75px;
    	--padding-start: 20px;
	}
	.save-search-button {
		position: absolute;
		top: -2px;
		right: 0;
		z-index: 9;
		margin: 0;
		width: 50px;
		height: 50px;
	}
	.close-save-search {
		position: absolute;
		top: -2px;
		right: 50px;
		z-index: 9;
		margin: 0;
		width: 22px;
		height: 22px;
		padding: 14px 4px;
		cursor: pointer;
	}
}