ion-content {
	--padding-start: 0px;
    --padding-end: 0px;
	@media screen and ( min-width: 576px ) {
		--padding-start: 16px;
    	--padding-end: 16px;
	}	
}

@media screen and ( min-width: 768px ) {
	.content {
		padding-top: 100px;
		max-width: 920px;
		margin: 0 auto;
		// .edit-profile & {
		// 	padding-top: 20px;
		// 	padding: 20px;
		// 	background-color: #fff;
		// 	ion-item, ion-list {
		// 		background-color: #fff;
		// 		--background: #fff;
		// 	}
		// }
		
	}
}