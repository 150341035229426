.edit-profile {
	
	@media screen and (min-width: 768px) {
		padding-top: 60px;
		ion-segment {
			
				margin-top: -54px;
			
		}
	}
}

.location-item {
	position: relative;
	z-index: 99;
}

.accolade-list {
	width: 100%;
	margin: 5px 0 0;
	padding-bottom: 0;
}

ion-buttons.buttons-end {
	justify-content: flex-end;
	padding-right: 5px;
	ion-button.link {
		margin: 0;
		padding: 5px 0;
		--padding-bottom: 5px;
		transition: 0.25s ease-out;
		&:hover {
			color: var(--ion-color-primary);
		}
	}
}

// .upload-image-wrap {
// 	width: 100%;
// 	padding: 15px 0;
// }

.upload-image .dropzone {
	display: block;
	border: 2px dashed #d2d2d2;
    border-radius: 3px;
	width: 100%;
	text-align: center;
	cursor: pointer;
}



.upload-image-wrap .circle-crop {
	border-radius: 100%;
	
}

.profile-picture-upload.upload-image-wrap .circle-crop {
	display: inline-block;
	width: 124px;
	height: 124px;
	border-radius: 75px;
	border: #d4d4d4 1px solid;
	background-color: #fff;
	overflow: hidden;
	position: relative;
}

.upload-progress {
	flex-basis: 100%;
	padding: 10px 0;
	margin: 0;
}

.social-media-totals {
	position: relative;
	.social-media-url {
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translate(-50%, 0);
		background-color: #f7f7f7;
		padding: 5px 10px;
		border-radius: 3px;
		display: none;
	
	}

	ion-icon.has-url {
		cursor: pointer;
	}
	.social-media-total.show-url .social-media-url {
		display: block;
	}
}

.social-media-fields {
	padding: 12px 0 0;
}

.editor-section .google-places {
	margin: 12px 0 0;
}

.editor-section .editor-section-bottom .social-media-field {
	position: relative;
	display: flex;
	align-items: center;
	margin-bottom: 12px;
	> div {
		margin: 0 0 0 10px;
		&.url {
			flex-grow: 1;
		}
	}
	.label {
		border-radius: 30px 0 0 30px;
		background-color: var(--ion-color-light);
		padding: 0 10px 0 15px;
		min-width: 60px;
		text-align: center;
		margin: 0;
		position: absolute;
		top: 0;
		height: 100%;
		border-right: 1px solid #dddddd;
		display: flex;
		align-items: center;
		justify-content: center;
		label {
			font-size: 0.8em;
			font-weight: 700;
		}

	}
	ion-input {
		margin: 0;
		margin-top: 0;
		border-radius: 30px;
		--padding-start: 70px;
		--padding-end: 15px;
	}
}

.editor-section .editor-section-bottom .accolade-field {
	position: relative;
	ion-input {
		margin-top: 8px;
	}
	ion-icon {
		position: absolute;
		right: 12px;
		top: 50%;
		transform: translate(0, -50%);
		font-size: 24px;
		z-index: 99;
		cursor: pointer;
	}
}