.tab-bar {
    display: block;
    z-index: 999;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto;
    display: flex;
    padding: 15px 8px;
    background-color: #fff;
    box-shadow: 0 3px 10px 0px rgba(0, 0, 0, 0.16);

    @media screen and (min-width: 768px) {
        bottom: auto;
        top: 0;
        padding-right: 20px;
    }
}

.tab-bar .logo-header {
    display: none;
    @media screen and (min-width: 768px) {
        display: flex;
        flex-grow: 1;
        align-items: center;
        padding: 3px 10px 0;
    }
    svg {
        height: 40px;
        width: auto;
        cursor: pointer;
        transition: 0.2s ease;
        &:hover {
            opacity: 0.8;
        }
    }
}

.tab-bar .tab-button {
    flex-grow: 1;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    color: #A8A8A8;
    cursor: pointer;

    @media screen and (min-width: 768px) {
        flex-grow: 0;
    }
}

.tab-bar .tab-button:hover {
    opacity: 0.7;
}

.tab-bar .tab-button ion-icon {
    flex-basis: 100%;
    font-size: 24px;
    padding: 5px;
} 

.tab-bar .tab-button.active {
    color: var(--ion-color-primary, #0EB59A);
    ion-label {
        color: var(--ion-color-primary, #0EB59A);
    }
} 

.tab-bar .tab-button ion-label {
    flex-basis: 100%;
    font-size: 12px;
}

.tab-bar .tab-button ion-badge {
    position: absolute;
    border-radius: 10px;
    width: 19px;
    height: 19px;
    left: 50%;
    margin: 0 0 0 15px;
    --padding-top: 2px;
}

.tab-bar .logout {
    display: none;
    margin-left: 12px;
    padding-left: 10px;
    border-left: 1px solid rgb(223, 223, 223);
    @media screen and (min-width: 768px) {
        display: flex;
    }
}


.tab-bar .login {
    display: flex;
    justify-content: flex-end;

    @media screen and (min-width: 768px) {
        order: 2;
        display: flex;
        margin-left: 12px;
        padding-left: 10px;
        border-left: 1px solid rgb(223, 223, 223);
    }
}

.tab-bar .signup {
    display: flex;
    order: 2;
    flex-grow: 0;
    justify-content: flex-end;
    justify-self: flex-end;
    
    @media screen and (min-width: 768px) {
        order: 1;
    }
}

