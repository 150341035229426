ion-button,
.pay-button {
	height: 65px;
	--border-radius: 40px; 
	border-radius: 40px; 
	
	font-size: 18px;
    font-weight: 500;
    letter-spacing: -0.01em;
    text-transform: inherit;
	margin: 12px 16px 0;
	--box-shadow: none;
	
	&.primary-button {
		--box-shadow: 0 3px 10px 0px rgba(0, 0, 0, 0.16);
		box-shadow: 0 3px 10px 0px rgba(0, 0, 0, 0.16);
	}
	&.button-secondary {
		--background: var(--ion-color-secondary);
	}
	&.button-tertiary {
		--background: var(--ion-color-light-shade);
		--color: var(--ion-color-dark);
	}

	&.ion-color-secondary {
		--box-shadow: 0;
	}
	&.ion-color-primary  {
		// linear-gradient(90deg,var(--c1,#f6d365),var(--c2,#fda085) 51%,var(--c1,#f6d365)) var(--x,0)/200%;
		--ion-color-primary: linear-gradient(-100deg, #00e8ce, #0da78d );
		// background: linear-gradient(-100deg, #00e8ce 51%, #0da78d)  100%;
		background: linear-gradient(-100deg, var(--c1, #00e8ce), var(--c2, #0da78d)) var(--x, 0)/ 150%;
		color: #fff;
		transition: 0.5s;
		&:hover {
			// background: linear-gradient(-100deg, #00e8ce 51%, #0da78d ) 200%;
			--x: 100%;
		}
	}
	&[button-type=link] {
		height: auto;
		--border-radius: 0;
		--box-shadow: none;
		margin: 0 auto;
		display: block;
		font-size: 0.9em;
		color: var(--ion-color-tertiary);
		padding: 16px 0;
		&.ion-align-self-end {
			margin: 0;
		}
	}
	&.button-small {
		height: 51px;
    	font-size: 16px;
		
	}
	&.button-block {
		display: block;
		display: block;
		width: 100%;
		margin: 16px 0;
	}
	&:disabled {
		opacity: 0.5;
		&:hover {
			// background: linear-gradient(-100deg, #00e8ce 51%, #0da78d ) 200%;
			--x: 0;
		}
	}

}

.favourite-button {
	color: var(--ion-color-primary);
	background-color: #F0F0F0;
	width: 42px;
	height: 42px;
	border-radius: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 5px 3px;
	cursor: pointer;
	transition: 0.2s ease-out;
	&:hover {
		// color: #fff;
		background-color: var(--ion-color-primary-tint);
	}
	&.smaller {
		width: 32px;
		height: 32px;
	}
	
}