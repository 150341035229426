.profile-header {
	
	background-color: var(--ion-color-medium);
	width: 100%;
	
	position: relative;
	overflow: hidden;
	max-height: 70vh;
    min-width: 100%;

	@media screen and (min-width: 576px) {
		
		margin-left: -16px;
		margin-right: -16px;
		padding: 0 16px;
		width: calc(100% + 32px);
		min-width: calc(100% + 32px);
	}

	@media screen and (min-width: 768px) {
		margin-top: 80px;
	}

	@supports (aspect-ratio: auto) {
		aspect-ratio: 12 / 5;
	}

	// aspect ratio fallback
	@supports not (aspect-ratio: auto) { 
		padding-top: 41.667%;
		
	}

	@media screen and (min-width: 576px) {

		@supports (aspect-ratio: auto) {
			aspect-ratio: 3 / 1;
		}

		// aspect ratio fallback
		@supports not (aspect-ratio: auto) { 
			padding-top: 33.333%;
			
		}
	}
	

	

	img {
		display: block;
		width: 100%;
		height: 100%;
		object-fit: cover;
		position: absolute;
		top: 0;
		left: 0;
		object-position: center center;
	
	}

		
}



.avatar {
	position: absolute;
	margin-top: -65px;
	margin-left: -62px;
    z-index: 99;
	text-align: center;
	left: 50%; 
	top: 0;

}

.avatar-image {
	display: inline-block;
	width: 124px;
	height: 124px;
	border-radius: 75px;
	border: #d4d4d4 1px solid;
	background-color: #fff;
	overflow: hidden;
	position: relative;
	ion-icon {
		width: 100%;
		height: 100%;
	}
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		position: absolute;
		top: 0;
		left: 0;
		object-position: center center;
		
	}
}

.profile-content-inner {
	@media screen and (min-width: 768px) {
		display: flex;
	}
	max-width: 1100px;
	margin : 0 auto;
	
}


.profile-info {
	width: 100%;
	max-width: 360px;
	margin: 0 auto 40px;
	background-color: #fff;
	padding: 65px 0 40px;
	position: relative;
	@media screen and (min-width: 768px) {
		margin: -40px 30px 60px 20px;
		align-self: flex-start;
	}
	border-radius: 5px;
	
}

.profile-opportunities {
	flex-grow: 1;
}

.profile-name {
	color: var(--ion-color-dark);
	text-transform: uppercase;
	letter-spacing: -0.025em;
	line-height: 1;
}

.profile-sport {
	color: var(--ion-color-primary);
	text-transform: uppercase;
	letter-spacing: -0.025em;
}

.social-media-total {
	a {
		text-decoration: none;
		transition: 0.25s ease;
		&[href]:hover {
			opacity: 0.7;
		}
		
	}
}



.social-media-total-figure {
	font-size: 0.9em;
	font-weight: 500;
	color: var(--ion-color-dark);
	text-decoration-style: unset;
}



.profile-detail {
	display: flex;
    align-items: center;
	color: var(--ion-color-dark);
	p {
		line-height: 1;
		padding: 0 0 0 12px;
		font-weight: 500;
		margin: 12px 0;
		word-break: break-all;
	}
	
}

.profile-detail-about {
	color: var(--ion-color-dark);
	h4 {
		font-family: var(--ion-font-family, sans-serif);
		font-weight: 600;
	}
}

.accolade {
	margin: 0 0 8px;
}

.read-more {
	display: none;
	@media screen and ( min-width: 768px ) {
		display: block;
	}

	font-size: 0.9em;
	text-decoration: underline;
	cursor: pointer;
	&:hover {
		color: var(--ion-color-primary-shade);
	}
}


.profile ion-toolbar {
	@media screen and ( min-width: 768px ) {
		position: fixed;
    	top: 80px;
		box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
	}
}

.profile .edit-profile-toolbar-buttons {
	justify-content: space-between;
	.edit-profile-button {
		max-width: 160px;
	}
	@media screen and ( min-width: 768px ) {
		justify-content: flex-end;
		.edit-profile-button {
			max-width: 180px;
		}
	}
}

.profile-tabs {
	background-color: #fff;
	padding: 40px 0px;
	flex-basis: 100%;
	margin-bottom: 140px;
	@media screen and ( min-width: 576px ) {
		padding: 40px 15px;
	}
	@media screen and ( min-width: 768px ) {
		margin-bottom: 80px;
		padding: 0 15px;
	}
	.profile-tab {
		display: block;
		@media screen and ( min-width: 768px ) {
			display: none;
		}
		.profile-tab-title {
			display: block;
			@media screen and ( min-width: 768px ) {
				display: none;
			}
		}
	}
	&.active-tab-1 {
		> div:nth-of-type(2) {
			display: block;
		}
	}
	&.active-tab-2 {
		> div:nth-of-type(3) {
			display: block;
		}
	}
	&.active-tab-3 {
		> div:nth-of-type(4) {
			display: block;
		}
	}
	&.active-tab-4 {
		> div:nth-of-type(5) {
			display: block;
		}
	}
	.profile-tab-navigation {
		display: none;
		flex-wrap: wrap;
		padding: 15px 0;
		@media screen and ( min-width: 768px ) {
			display: flex;
		}
		p {
			padding: 5px 15px;
			margin: 0;
			font-weight: 700;
			color: var(--ion-color-dark);
			cursor: pointer;
			transition: 0.25s ease-out;
			position: relative;
			&:hover {
				color: var(--ion-color-primary);
			}
			&.active {
				color: var(--ion-color-primary);
				cursor: default;
				// &:before {
				// 	content: "";
				// 	position: absolute;
				// 	width: 6px;
				// 	height: 6px;
				// 	border-radius: 4px;
				// 	background-color: var(--ion-color-primary);
				// 	left: calc(50% - 4px);
				// 	top: calc(100% + 2px);
				// }
				&:hover {
					opacity: 1;
				}
			}
			
		}
	}
}

.tab-title {
	font-family: 'neue-haas-unica', sans-serif;
	font-weight: 700;
	letter-spacing: -0.01em;
	font-size: 1.8em;
	padding: 0 18px 18px;
}

.profile-tabs .photos {
	.profile-images {
		padding: 15px 5px;
		margin: 15px 0 80px;
		background-color: #fff;
		.profile-image {
			max-width: 50%;
			padding: 3px;
			cursor: pointer;
			transition: 0.25s ease-out;
			@media screen and (min-width: 450px) {
				max-width: 50%;
				padding: 1px;
			}
			@media screen and (min-width: 620px) {
				max-width: 25%;
				padding: 2px;
			}
			@media screen and (min-width: 768px) {
				max-width: 33.333%;
				padding: 2px;
			}
			@media screen and (min-width: 992px) {
				max-width: 25%;
				padding: 2px;
			}
			&:hover {
				opacity: 0.6;
			}
		}
	}
}