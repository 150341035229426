.text-editor-content {
	padding: 0 16px 16px;
	user-select: text;
    white-space: pre-wrap;
    overflow-wrap: break-word;

	color: var(--ion-color-dark);

	h1, h2, h3, h4, h5, h6 {
		font-family: 'neue-haas-unica', sans-serif;
		font-weight: 700;
		letter-spacing: -0.01em;
		
	}

	h2 {
		font-size: 1.8em;
	}
}