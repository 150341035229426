.menu-list {
	background-color: #fff;
	padding: 8px 6px;
	border-radius: 40px;
	max-width: 920px;
	margin: 0 auto;
	.menu-list-option {
		border-radius: 40px;
		padding: 5px 7px;
		display: flex;
		align-items: center;
		background-color: transparent;
		transition: 0.25s background-color ease-out;
		cursor: pointer;
		&:hover {
			background-color: var(--ion-color-light);
		}
		.icon {
			width: 57px;
			height: 57px;
			background-color: #E0FAF3;
			border-radius: 30px;
			transition: 0.25s background-color ease-out;
			position: relative;
			ion-icon {
				position: relative;
				width: 32px;
				height: 32px;
				padding: 13px;
			}
		}
		&.active {
			background-color: var(--ion-color-primary-tint);
			.icon {
				background-color: #fff;
			}
		}
		.text {
			padding: 10px;
		}
		p {
			margin: 0;
			color: var(--ion-color-dark);
			&.main-text {
				font-weight: 600;
				line-height: 1;
				margin-bottom: 5px;
				letter-spacing: -0.01em;
			}
			&.sub-text {
				font-size: 0.8em;
				line-height: 1;
				color: var(--ion-color-medium);
				margin-bottom: 2px;
			}
		}
	}
}
